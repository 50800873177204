import { json } from '@remix-run/react';
import { decryptEncryptedManagedKey } from '~/lib/e2ee.client';
import { decodeBase64, deriveSymmetricKey, encodeBase64, getKeyPair, login, seal } from '~/lib/session.client';

type DesktopAuthParams = {
  email: string;
  saltEnc: string;
  encryptionEnabled: boolean;
  encDriverKey: string;
  encSymmetricKey: string;
  encPrivateKey: string;
  passphrase?: string;
  loginKey: string;
};

export async function authenticateForDesktop({
  email,
  saltEnc,
  passphrase,
  encDriverKey,
  encryptionEnabled,
  encPrivateKey,
  encSymmetricKey,
  loginKey,
}: DesktopAuthParams) {
  try {
    let encPassphrase = passphrase;
    if (encryptionEnabled) {
      const { publicKey, privateKey } = await getKeyPair();

      const managedKeyResponse = await fetch(`${window.ENV.INSOMNIA_API_URI}/v1/web/encryption/retrieve-managed-key`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          publicKey: JSON.stringify(publicKey),
        }),
      });

      if (!managedKeyResponse.ok) {
        const { message } = await managedKeyResponse.json();
        return json({ errors: [message] }, managedKeyResponse.status);
      }

      const managedKeyRata = (await managedKeyResponse.json()) as { managedKey: string };
      encPassphrase = decryptEncryptedManagedKey(privateKey, managedKeyRata.managedKey);
    }

    if (!encPassphrase) {
      return json({ errors: ['Passphrase cannot be empty.'] });
    }

    const rawToken = await login(email, encPassphrase, encDriverKey, undefined, false);
    const rawKey = await deriveSymmetricKey(
      { email, saltEnc, encPrivateKey, encSymmetricKey, encDriverKey },
      encPassphrase,
    );
    const box = { token: rawToken, key: rawKey };

    const decodedLoginKey = await decodeBase64(loginKey);

    const enc = new TextEncoder();
    const token = await encodeBase64(seal(enc.encode(JSON.stringify(box)), decodedLoginKey));

    const encodedToken = window.encodeURIComponent(token);

    return { token: encodedToken };
  } catch (err: any) {
    if (err.name === 'TypeError') {
      return { errors: ['Try again after disabling any CORS extension from your computer.'] };
    }
    return { errors: [err.message] };
  }
}
